button {
    background: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    cursor: pointer;
    margin: 0;
    overflow: visible;
    padding: 0;
    width: auto;
    -webkit-appearance: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
}

button {
    border: 1px solid rgba(var(--color-accent), 1);
    color: rgba(var(--color-accent), 1);
    font-weight: bold;
    font-size: 1.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 5%;
    padding: 1rem 2.4rem;
    position: relative;

    transition: color .35s linear;
}

button::before {
    background-color: rgba(var(--color-accent), 1);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0px;
    transition: width .35s ease-in-out;
    z-index: -1;
}

button:hover {
    color: rgba(var(--color-primary), 1);
}

button:hover::before {
    width: 100%;
}
