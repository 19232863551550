@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-primary: 36, 73, 82;
  --color-accent: 229, 224, 216;
}

/* CSS Reset */
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

html {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  min-height: 100%;
}

body {
  background-color: rgba(var(--color-primary), 1);
  display: flex;
  color: white;
  font-size: 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: auto;
}

.container {
  padding-top: 200px;
  padding-inline: 24px;
  margin-inline: auto;
  position: relative;
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  z-index: 2;
}

.welcome {
  color: rgba(var(--color-accent), 1);
  font-family: 'DM Serif Text', serif;
  font-size: 1.5rem;
  letter-spacing: 1%;
  line-height: 1.4;
  font-weight: 400;
}

@media screen and (min-width: 480px) {
  .welcome {
    font-size: 2.25rem;
  }
}

.watermark {
  color: rgba(var(--color-accent), .1);
  content: '';
  position: fixed;
  top: 200px;
  height: auto;
  left: 50%;
  width: 50%;
  z-index: 1;
}

.watermark svg {
  object-fit: contain;
  height: auto;
  width: 100%;
}

#touchbynatalja {
  color: rgba(var(--color-accent), 1);
}

#touchbynatalja-natalja {
  stroke: rgba(var(--color-accent), 1);
  stroke-width: 1;
  stroke-dashoffset: 2200;
  stroke-dasharray: 2200;
  animation: offset 1.5s 1.25s linear forwards, fill-it .8s 2.75s forwards;
}

#touchbynatalja-touch {
  opacity: 0;
  animation: fade-in .8s .5s forwards;
}

#touchbynatalja-by {
  opacity: 0;
  animation: fade-in .8s .85s forwards;
}

.social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.social-media .item {
  color: rgba(var(--color-accent), 1);
  padding: .5rem;
  cursor: pointer;
  transition: opacity .25s ease-in-out;
}

.social-media .item:hover {
  opacity: .8;
}

.social-media .item svg {
  height: 2rem;
  width: 2rem;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes offset {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-it {
  50% {
    stroke-width: 1;
  }
  100% {
    fill: rgba(var(--color-accent), 1);
    stroke-width: 0;
  }
}
