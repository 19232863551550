.preloader {
    background-color: rgba(var(--color-primary), 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 0;
    z-index: 1000;
    padding-inline: 32px;

    animation: shrink 1s 3.55s ease-in-out forwards;
}

.preloader svg {
    height: 50%;
    max-height: 512px;
    width: 50%;
}

@keyframes shrink {
    100% {
        height: 200px;
    }
}
